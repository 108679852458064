@font-face {
  font-family: 'FiraSans';
  src: url('./FIRASANS-LIGHT.woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('./FIRASANS-REGULAR.woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('./FIRASANS-MEDIUM.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('./FIRASANS-BOLD.woff2');
  font-weight: 600;
  font-style: normal;
}
